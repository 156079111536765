import React from "react"
import * as md5 from "md5"
import classNames from "classnames"
import CloseIcon from "../../images/close.svg"
import ReactDOMServer from 'react-dom/server';

import "./notification.sass"

class Notification extends React.Component {

  constructor(props) {
    super(props)
    this.state = { notificationTitle: "Notification", notificationBody: <p>Das ist eine Notification</p>, showUntil: new Date('2021-05-10'), hidden: true }
  }

  closeNotification(hash) {
    console.log(hash)
    localStorage.setItem('notification', hash)
    this.setState({ hidden: true })
  }

  componentDidMount() {
    if (this.state.showUntil.getTime() < new Date().getTime() || localStorage.getItem('notification') === md5(ReactDOMServer.renderToStaticMarkup(this.state.notificationBody) + this.state.notificationTitle)) {
      this.setState({ hidden: true })
    } else {
      this.setState({ hidden: false })
    }
  }


  render() {

    return <div className={classNames("_6d", { "_6k": !this.state.hidden })}>
      <div className="_6j">
      </div>
      <div className="_6e">
        <div className="_6f">
        <h3 className="_6h">
          {this.state.notificationTitle}
        </h3>
        <div className="_6g"
             onClick={() => this.closeNotification(md5(ReactDOMServer.renderToStaticMarkup(this.state.notificationBody) + this.state.notificationTitle))}>
          <CloseIcon></CloseIcon>
        </div>
        </div>
        <div className="_6i">
          {this.state.notificationBody}
        </div>
      </div>
    </div>
  }
}

export default Notification
