/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import {useStaticQuery, graphql} from "gatsby"
import {globalHistory} from "@reach/router";

function SEO({description, showPageTitleSuffix, title, primaryImage, children, ...props}) {

  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            author
            logoUrl
            description
            organisationLegalName
            organisationAlternateName
            organisationName
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description
  console.log('---')
  console.log(title)
  console.log(description)
  console.log(metaDescription)

  return (

    <Helmet
      htmlAttributes={{lang: 'de'}}
      title={title}
      titleTemplate={`%s${showPageTitleSuffix ? " | " + site.siteMetadata.title : ""}`}
    >

      {/*Favicon*/}
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png"/>
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png"/>
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png"/>
      <link rel="manifest" href="/favicon/site.webmanifest"/>
      <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#0d6b4e"/>
      <meta name="msapplication-TileColor" content="#0d6b4e"/>
      <meta name="msapplication-config" content="/favicon/browserconfig.xml"/>
      <meta name="theme-color" content="#0d6b4e"/>
      <meta name="apple-mobile-web-app-title" content={site.siteMetadata.title}/>
      <meta name="application-name" content={site.siteMetadata.title}/>
      {/*End Favicon*/}

      <meta name="description" content={metaDescription}/>
      <meta property="og:title" content={title + " | " + site.siteMetadata.title}/>
      <meta property="og:description" content={metaDescription}/>
      <meta property="og:type" content='website'/>
      <meta property="og:image" content={site.siteMetadata.siteUrl + primaryImage}/>
      <meta name="twitter:card" content='summary'/>
      <meta name="twitter:creator" content={site.siteMetadata.author}/>
      <meta name="twitter:title" content={title + " | " + site.siteMetadata.title}/>
      <meta name="twitter:image" content={site.siteMetadata.siteUrl + primaryImage}/>
      <meta name="twitter:image:alt" content={metaDescription}/>
      <meta name="twitter:description" content={metaDescription}/>

      {/* Schema */}

      <script type="application/ld+json">
        {`{
                    "@context": "https://schema.org",
                    "@graph": [
                        {
                            "@type": "Corporation",
                            "url": "${site.siteMetadata.siteUrl}",
                            "name": "${site.siteMetadata.organisationName}",
                            "alternateName": "${site.siteMetadata.organisationAlternateName}",
                            "legalName": "${site.siteMetadata.organisationLegalName}",
                            "logo": "${site.siteMetadata.logoUrl}",
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "telephone": "+491732717781",
                                "contactType": "Office",
                                "email": "info@fahrschulerauscher.de"
                            },
                            "address": {
                                "streetAddress": "Aspacher Straße 53",
                                "postalCode": "71522",
                                "addressCountry": "DE",
                                "addressRegion": "Baden-Württemberg",
                                "addressLocality": "Backnang"
                            }
                        },
                        {
                            "@type":"WebSite",
                            "@id":"${site.siteMetadata.siteUrl}/#website",
                            "url":"${site.siteMetadata.siteUrl}",
                            "name": "${site.siteMetadata.title}",
                            "description":"${site.siteMetadata.description}",
                        }
                        
                    ]
                }`}
      </script>
      {globalHistory.location.pathname &&
      <script type="application/ld+json">
        {`{
                    "@context":"https://schema.org",
                    "@type":"WebPage",
                    "@id":"${site.siteMetadata.siteUrl + globalHistory.location.pathname}#website",
                    "url":"${site.siteMetadata.siteUrl + globalHistory.location.pathname}",
                    "name":"${title + (showPageTitleSuffix ? " | " + site.siteMetadata.title : "")}",
                    "isPartOf":{"@id":"${site.siteMetadata.siteUrl}/#website"},
                    "description":"${metaDescription}", 
                    ${primaryImage ? `"primaryImageOfPage": {"@id":"${site.siteMetadata.siteUrl + primaryImage}", "contentUrl": "${site.siteMetadata.siteUrl + primaryImage}"},` : ""}
                    "inLanguage":"de"
                }`}</script>
      }

      {children}

    </Helmet>
  )
}

SEO.defaultProps = {
  showPageTitleSuffix: true,
  primaryImage: '/images/fahrschule-rauscher.jpg'
}

SEO.propTypes = {
  showPageTitleSuffix: PropTypes.bool,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  primaryImage: PropTypes.string,
}

export default SEO
