/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Header from '../header/header';
import Footer from '../footer/footer';
import './layout.sass';
import Notification from "../notification/notification"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);


  return (
    <>
      <div className={"_5d"} onClick={() => {
        window.klaro.show()
      }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
          <path
            d="M396 163a30 30 0 00-40-22 76 76 0 01-102-72c0-9 1-18 4-26a30 30 0 00-22-40A201 201 0 0016 122a198 198 0 0042 218 199 199 0 00325-63 198 198 0 0013-114z"
            className="st0"/>
          <path
            d="M396 163a30 30 0 00-40-22 76 76 0 01-102-72c0-9 1-18 4-26a30 30 0 00-22-40A201 201 0 0016 122a198 198 0 0042 218 199 199 0 00325-63 198 198 0 0013-114zm-57 132a35 35 0 10-43 44A169 169 0 0178 82a30 30 0 1045-34 169 169 0 01107-15 106 106 0 00136 136 170 170 0 01-27 126z"
            className="st1"/>
          <circle cx="115" cy="183" r="43" className="st1"/>
          <circle cx="237" cy="207" r="38" className="st1"/>
          <circle cx="167" cy="295" r="32" className="st1"/>
        </svg>

      </div>
      <Notification></Notification>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>

        <Footer siteTitle={data.site.siteMetadata.title} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
