import React, { useState } from "react"
import RauscherLogo from "../../images/logo.svg"
import classNames from "classnames"

import "./header.sass"
import Link from "gatsby-link"
import NavIcon from "./nav-icon/nav-icon"
import { useWindowScroll } from "react-use"

const Header = () => {
  const scrolled = useWindowScroll().y > 0;
  const [active, setActive] = useState(false)

  const nav = [
    {
      title: "Auto", path: "/auto/", children: [
        { title: "Allgemeine Informationen", path: "/auto/" },
        { title: "Klasse B", path: "/auto/klasse-b/" },
        { title: "Klasse BE", path: "/auto/klasse-be/" },
        { title: "Klasse B196", path: "/auto/klasse-b196/" },
        { title: "Klasse B197", path: "/auto/klasse-b197/" },
      ],
    },
    {
      title: "Motorrad", path: "/motorrad/", children: [
        { title: "Allgemeine Informationen", path: "/motorrad/" },
        { title: "Klasse A", path: "/motorrad/klasse-a/" },
        { title: "Klasse A1", path: "/motorrad/klasse-a1/" },
        { title: "Klasse A2", path: "/motorrad/klasse-a2/" },
        { title: "Klasse AM", path: "/motorrad/klasse-am/" },
      ],
    },
    {
      title: "ASF-Seminar", path: "/asf-seminar/",
    },
    { title: "Anmeldung", path: "/anmeldung/" },
    {
      title: "Fahrschul-Wechsel",
      path: "/fahrschul-wechsel/",
    },
    { title: "Kontakt, Anfahrt & Öffnungszeiten", path: "/kontakt/" },
  ]

  return <header
    className={classNames("_2", { "_4": scrolled })}>
    <div className="_5 _14">
      <div className="_6">
        <Link className={"_4n _4s"} to={"/"}>
          <RauscherLogo/>
        </Link>
      </div>
      <div className="_7" onClick={() => setActive(!active)}>
        <NavIcon active={active}/>
      </div>
    </div>
    <nav className={classNames("_h", { "_j": active })}>
      <div className="_3" onClick={() => setActive(false)}></div>
      <div className="_8">
        {nav.map((navItem, index) => {
          return <div key={index}
                      className={classNames("_a", { "_b": navItem.children?.length > 0 })}>
            <Link to={navItem.path} className={"_4n _4q _c"} partiallyActive
                  activeClassName={"_d"}>
              {navItem.title}
            </Link>
            {navItem.children?.length > 0 &&
            <div className={"_e"}>
              {navItem.children.map((child, index) => {
                return <Link key={index} activeClassName={"_g"}
                             className={"_f _4n"}
                             to={child.path}>{child.title}</Link>
              })}
            </div>
            }
          </div>
        })}
      </div>
    </nav>
  </header>
}

export default Header
