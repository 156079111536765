import React from "react"
import "./social-media-icons.sass"
import classNames from "classnames"
import InstagramIcon from "../../images/social-media/instagram.svg"
import FacebookIcon from "../../images/social-media/facebook.svg"

const SocialMediaIcons = ({ small }) => {

  return <div className={classNames("_t", { "_w": small })}>
    <a className={"_4n _4s"} href="https://instagram.com" target={"_blank"}>
      <InstagramIcon></InstagramIcon>
    </a>
    <a className={"_4n _4s"} href="https://facebook.com" target={"_blank"}>
      <FacebookIcon></FacebookIcon>
    </a>
  </div>
}

export default SocialMediaIcons
